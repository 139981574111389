import Rx from 'rx';
import dayjs from 'dayjs';

import { defineStore } from 'pinia';
import { IActivity } from '../../types/IActivity';
import AppServer from '../../api/AppServer';
import Query from '../../api/Query';
import QueryCommand, { IQueryCommandResponse } from '../../api/QueryCommand';
import WriteCommand from '../../api/WriteCommand';

interface IUIStateActivity {
    _activity: any;
}

let updateActivityDisposable!: Rx.Disposable;

export const useStoreActivity = defineStore('activity', {
    state: () => {
        return {
            _activity: {} as any,
        };
    },
    getters: {
        activity: (state: IUIStateActivity) => state._activity as any,
    },
    actions: {
        fetchActivity({ organization, activityId, partitionIds }) {
            let cmd = new QueryCommand(organization.get('organization') as number);
            let query = new Query('activity');
            if (partitionIds && partitionIds.length > 0) {
                query = query.partitionIds(partitionIds);
            }
            cmd = cmd.query(query.id(activityId)).sortKey('id').limit(1);
            const conn = AppServer.connection(organization);
            return conn
                .execute(cmd)
                .filter((response: IQueryCommandResponse<IActivity>) => {
                    // Return true if the query matches the response, or if the response contains the activity ID
                    return (
                        response.result.matchesQuery ||
                        (response.result.data.length > 0 &&
                            response.result.data.filter((activity) => activity.id === parseInt(activityId, 10)).length >
                                0)
                    );
                })
                .flatMapLatest((response: IQueryCommandResponse<IActivity>) => {
                    this._activity = response.result.data.filter(
                        (activity) => activity.id === parseInt(activityId, 10)
                    )[0];
                    if (this._activity.date) {
                        this._activity.__dayjsDt = dayjs(this._activity.date);
                    }
                    return Rx.Observable.of(this._activity);
                });
        },

        updateActivity({ organization, params }) {
            const command = new WriteCommand<Record<string, any>>('activity', organization.get('organization'));
            if (updateActivityDisposable) {
                updateActivityDisposable.dispose();
            }

            return new Promise((resolve, reject) => {
                updateActivityDisposable = AppServer.connection(organization)
                    .execute(command.data(params))
                    .first()
                    .subscribe((response) => {
                        resolve(response);
                    }, reject);
            });
        },

        reset() {
            if (updateActivityDisposable) {
                updateActivityDisposable.dispose();
            }
            this._activity = {};
            this.$reset();
        },
    },
});
